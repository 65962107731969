import React from 'react';

class Hue extends React.Component {
    render() {
        return(
            <div>
                <h2>Under Construction</h2>
            </div>
        );
    }
}

export default Hue;





