import React from 'react';
import PropTypes from 'prop-types';
import TimelineMoment from './TimelineMoment';

class Timeline extends React.Component {
    render() {
        return(
            <section id="timeline">
                <div className="container text-left">
                    <div className="page-header">
                        <h1 id="timeline">Professional Timeline</h1>
                    </div>
                    <ul className="timeline">
                        {this.props.moments.map((moment, index) => (
                            <TimelineMoment moment={moment} key={index} />
                        ))}
                    </ul>
                </div>
            </section>
        );
    }
}

Timeline.propTypes = {
    moments: PropTypes.array
}

Timeline.defaultProps = {
    moments: [
        {	
            company:{name:'Salesforce, Inc.', 
                dates: '2017 - Current', 
                logo:'salesforce.png',
                blurb:'Lead Member of Technical Staff. Lead developer in IT Applications group responsible for Employee Success applications used by 20,000+ employees.',
                link:'https://www.salesforce.com/',	
                },
            quote:{
                name:'Rakesh Boinepalli',
                title:'Senior Software Engineer', 					
                text:'I\'ve never seen a developer who is so new to Salesforce technology but master it in such a short span of time. I\'m super happy to work with Ben and utilize every opportunity to learn from him and improve myself, both techincally and personally. He is truly a rockstar.'},
            invert: false,
            dateBadge: '17',
            id:'salesforceId',
            badge:'info'
        },
        {	
            company:{name:'TiVo, Inc.', 
                dates: '2011 - 2016', 
                logo:'tivo.png',
                blurb:'Designed backend services for using SOA pattern with RESTful services. Implemented MuleSoft data synchronization between SAP and SalesForce CRM. Leveraged SpringMVC framework to implement required services.',
                link:'https://www.tivo.com/',	
                },
            quote:{
                name:'Jammy Pate',
                title:'Director of IT',
                text:'My go-to person.'},
            invert: true,
            dateBadge: '16',
            id:'tivoId',
            badge:'warning'
        },
        {	
            company:{name:'Foothill College', 
                dates: '2009 - 2011', 
                logo:'foothill.png',
                blurb:'3.9/4.0 GPA Coursework focused on computer science (C++, Java, data structures and algorithms, discrete math, linear algebra).',
                link:'https://www.foothill.edu/',	
                },
            quote:{
                name:'Danny Tran',
                title:'Professor',
                text:'It was a joy to have Ben in class.'},
            invert: true,
            dateBadge: '11',
            id:'foothillId',
            badge:'primary'
        },
        {	
            company:{name:'The Picture People', 
                dates: '2007 - 2010', 
                logo:'TPP_Logo.png',
                blurb:'Created company Facebook page, Twitter account, and corporate blog. Ran first company SEM and Facebook advertising campaigns; following year granted $700,000 budget for further SEM campaigns',
                link:'http://www.picturepeople.com/',	
                },
            quote:{
                name:'Roz Greenfield',
                title:'Manager',
                text:'Ben led several key initiatives during his tenure with me. He launched our social media program under his own management after successfully communicating its value to the company leadership and being awarded a budget to do so. Following on this achievement, Ben developed an SEM program for our fourth quarter which drove a significant ROI. His outstanding analytical abilities helped the team use metrics to demonstrate the value of our local and guerrilla marketing tactics.'},
            invert: false,
            dateBadge: '10',
            id:'tppId',
            badge:'danger'
        },
        {	
            company:{name:'Rodale, Inc.', 
                dates: '2005 - 2006', 
                logo:'rodale_logo.gif',
                blurb:'Interned with Bicycling Magazine. Part of CMS migration, recognized for taking most actions in system of all team members.',
                link:'http://www.rodale.com/',	
                },
            quote:{
                name:'Bob de Garmo',
                title:'Vendor',
                text:'Ben has a good sense of how to use technology to support the sales organization. He is both strategic and practical in finding and implementing ways to drive sales and profit.'},
            invert: true,
            dateBadge: '06',
            id:'rodaleId',
            badge:'success'
        },
        {	
            company:{name:'Knox College', 
                dates: '2001 - 2005', 
                logo:'knox.svg',
                blurb:'B.A. Degree in English Literature, Minor in Journalism',
                link:'https://www.knox.edu/',	
                },
            quote:{
                name:'Lori Haslem',
                title:'Professor',
                text:'I could always count on Ben to elevate the level of discussion.'},
            invert: false,
            dateBadge: '05',
            id:'knoxId',
            badge:''
        },
    ]
}


export default Timeline;

