import React from 'react';
import PropTypes from 'prop-types';
import Card from '../components/Card';

class Favorites extends React.Component {
    componentDidMount(){
        console.log('Component did mount');
    }

    render() {
        var faves = groupBy(this.props.favorites,"type");
        return(
            <div className="bg-secondary shadow-inverse">
                <div className="container">
                    {
                        Object.keys(faves).map((keyName, index) => (
                        <div className="row no-gutter p-md-4 text-justify mt-3" key={index}>
                            <h1 className="text-uppercase underline" >{keyName}</h1>
                            {faves[keyName].map((fave, i) => (
                                <div className="col-lg-3 col-sm-4 col-xs-12" key={i}>
                                    <Card name={fave.name} type={keyName} image={fave.cover} text={fave.text}></Card>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="mt-3"></div>
                </div>

            </div>
        );
    }
}

//I could get this from lodash but so far this is my only need so just writing a little code snippet
const groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...acc[cur[property]] || [], cur];
        return acc;
    }, {});
}

Favorites.propTypes = {
    favorites: PropTypes.array
}

Favorites.defaultProps = {
    favorites:
    [
        { type:'games', name: 'Morrowind', text: '“Wake up. We\'re here.”', cover: 'morrowind_coverxs.jpg' },
        { type:'games', name: 'Alpha Centauri', text: '“If our society seems more nihilistic than that of previous eras, perhaps this is simply a sign of our maturity as a sentient species. As our collective consciousness expands beyond a crucial point, we are at last ready to accept life\'s fundamental truth: that life\'s only purpose is life itself.”', cover: 'alpha_centauri_coverxs.jpg' },
        { type:'games', name: 'Bioshock', text: '“These sad saps. They come to Rapture thinking they\'re gonna be captains of industry, but they all forget that somebody\'s gotta scrub the toilets.”', cover: 'bioshock_coverxs.jpg' },
        { type:'games', name: 'The Witness', text: '“If we did not have a doubt or recognize ignorance, we would not get any new ideas.”', cover: 'witness_coverxs.jpg' },
        { type:'games', name: 'StarCraft', text: '“I\'m having doubts about this, Arcturus. I just don\'t think anyone deserves to have the zerg unleashed on them.”', cover: 'starcraft_coverxs.jpg' },
        { type:'games', name: 'Mass Effect 2', text: '“They tell me it\'s a suicide mission. I intend to prove them wrong.”', cover: 'mass_effect_coverxs.jpg' },
        { type:'games', name: 'Final Fantasy VII', text: '“I\'ll be going now. I\'ll come back when it\'s all over.”', cover: 'ff7_coverxs.jpg' },
        { type:'games', name: 'The Last of Us', text: '“Tell them that... Ellie is the little girl... that broke your fucking finger!”', cover: 'last_of_us_coverxs.jpg' },
        { type:'games', name: 'Elden Ring', text: '“But I would not acquiesce to the Two Fingers. I stole the Rune of Death, slew mine own Empyrean flesh, casting it away. I would not be controlled by that thing.”', cover: 'elden_ringxs.jpg' },
        { type:'games', name: 'Marathon 2', text: '“Perhaps it is because I feel comfortable manipulating humans that I desire to save them. My feelings and thoughts constantly migrate to binary opposites.”', cover: 'marathon_coverxs.jpg' },
        { type:'games', name: 'Portal 2', text: '“Did you know that people with guilty consciences are more easily startled by loud noises? [train horn] I\'m sorry, I don\'t know why that went off. Anyway, just an interesting science fact.”', cover: 'portal_2_coverxs.jpg' },
        { type:'games', name: 'Super Smash Bros. Melee', text: '“Super Smash Brothers... Meleeeeeeeeee!”', cover: 'smash_brothers_coverxs.jpg' },

        { type:'television', name: 'The Wire', text: '“You know the problem with these here machines? They too cheap to begin with. Some people think for what it\'s worth to fix it, make the shit work right, you might as well dump \'em and get another.” ~ Proposition Joe Stewart', cover: 'thewirexs.jpg' },
        { type:'television', name: 'Sopranos', text: '“It\'s good to be in something from the ground floor. I came too late for that and I know. But lately, I\'m getting the feeling that I came in at the end. The best is over.” ~ Tony Soprano', cover: 'sopranosxs.jpg' },
        { type:'television', name: 'Deadwood', text: '“Truth is, as a base of operations, you cannot beat a fucking saloon.” ~ Al Swearengen', cover: 'deadwoodxs.jpg' },
        { type:'television', name: 'Simpsons', text: '“Television! Teacher! Mother! Secret lover.” ~ Homer Simpson', cover: 'simpsonsxs.jpg' },
        { type:'television', name: 'Mad Men', text: '“Well, technology is a glittering lure. But there\'s the rare occasion when the public can be engaged on a level beyond flash, if they have a sentimental bond with the product.” ~ Don Draper', cover: 'madmenxs.jpg' },
        { type:'television', name: 'Freaks and Geeks', text: '“Everyone looks cool in turtle necks. That\'s the point! We can\'t both wear them; we\'ll look like the Smothers Brothers!” ~ Neal Schweiber', cover: 'freaksandgeeksxs.jpg' },
        { type:'television', name: 'Better Call Saul', text: '“If you don\'t like where you\'re heading, there\'s no shame in going back and changing your path.” ~ Charles McGill', cover: 'bettercallsaulxs.jpg' },
        { type:'television', name: 'Archer', text: '“I\'m not saying I invented the turtleneck, but I was the first person to realize its potential as a tactical garment. The tactical turtleneck! The … tactleneck.” ~ Sterling Archer', cover: 'archerxs.jpg' },
        { type:'television', name: 'Arrested Development', text: '“Oh, please. I\'ve been drinking since before you were born. So if alchohol is the reason I\'m here, I\'ve got news for you, bub. It\'s the reason you\'re here too.” ~ Lucille Bluth', cover: 'arresteddevelopmentxs.jpg' },
        { type:'television', name: 'Game of Thrones', text: '“Never forget what you are, the rest of the world will not. Wear it like armor and it can never be used to hurt you.” ~ Tyrion Lannister', cover: 'gameofthronesxs.jpg' },
        { type:'television', name: 'Atlanta', text: '“I just think we need a chance as humans to fail in order to discover what actually works, you know?” ~ Earnest Marks', cover: 'atlantaxs.jpg' },
        { type:'television', name: 'Legion', text: '“Who teaches us to be normal when we\'re one of a kind?” ~ Syd Barrett', cover: 'legionxs.jpg' },

        { type:'movies', name: 'Chinatown', text: '“Forget it, Jake, it\'s Chinatown.” ~ Walsh', cover: 'chinatownxs.jpg' },
        { type:'movies', name: 'City of God', text: '“You need more than guts to be a good gangster. You need ideas.” ~ Buscapé', cover: 'city_of_godxs.jpg' },
        { type:'movies', name: 'Children of Men', text: '“What do the police know about justice?” ~ Julian Taylor', cover: 'children_of_menxs.jpg' },
        { type:'movies', name: 'Cool Hand Luke', text: '“Yeah, well, sometimes nothing can be a real cool hand.” ~ Luke', cover: 'cool_hand_lukexs.jpg' },
        { type:'movies', name: 'Pulp Fiction', text: '“That\'s when you know you\'ve found somebody special. When you can just shut the fuck up for a minute and comfortably enjoy the silence.” ~ Mia Wallace', cover: 'pulp_fictionxs.jpg' },    		
        { type:'movies', name: 'The Thing', text: '“Why don\'t we just... wait here for a little while... see what happens?” ~ MacReady', cover: 'the_thingxs.jpg' },
        { type:'movies', name: 'Lost in Translation', text: '“...I have to be leaving, but I won\'t let that come between us. Okay?” ~ Bob Harris', cover: 'lost_in_translationxs.jpg' },
        { type:'movies', name: 'Terminator 2', text: '“The system goes online August 4th, 1997. Human decisions are removed from strategic defense. Skynet begins to learn at a geometric rate. It becomes self-aware at 2:14 a.m. Eastern time, August 29th. In a panic, they try to pull the plug.” ~ The Terminator', cover: 'terminator_2xs.jpg' },
        { type:'movies', name: 'No Country for Old Men', text: '“1958. It\'s been traveling twenty-two years to get here. And now it\'s here. And it\'s either heads or tails. And you have to say. Call it.” ~ Anton Chigurh', cover: 'no_country_for_old_menxs.jpg' },
        { type:'movies', name: 'The Third Man', text: '“In Italy for thirty years under the Borgias, they had warfare, terror, murder, bloodshed. They produced Michaelangelo, da Vinci, and the Renaissance. In Switzerland, they had brotherly love, five hundred years of democracy and peace, and what did they produce? The cuckoo clock.” ~ Harry Lime', cover: 'third_manxs.jpg' },
        { type:'movies', name: 'Mad Max: Fury Road', text: '“Then who killed the World?” ~ The Splendid Angharad', cover: 'mad_maxxs.jpg' },
        { type:'movies', name: 'Lord of the Rings', text: '“Pity? It was pity that stayed Bilbo\'s hand. Many that live deserve death. Some that die deserve life. Can you give it to them, Frodo? Do not be too eager to deal out death in judgment. Even the very wise cannot see all ends.” ~ Gandalf the Grey', cover: 'lord_of_the_ringsxs.jpg' },

        { type:'books', name: 'Dune', text: '“The vision of time is broad, but when you pass through it, time becomes a narrow door.”', cover: 'dunexs.jpg' },
        { type:'books', name: 'An Immense World', text: '“Sensing can feel passive, as if eyes and other sense organs were intake valves through which animals absorb and recieve the stimuli around them. But over time, the simple act of seeing recolors the world. Guided by evolution, eyes are living paintbrushes.”', cover: 'immense_worldxs.jpg' },
        { type:'books', name: 'Game of Thrones', text: '“My brother has his sword, King Robert has his warhammer and I have my mind...and a mind needs books as a sword needs a whetstone if it is to keep its edge. That\'s why I read so much, John Snow” ~ Tyrion Lannister', cover: 'game_of_thronesxs.jpg' },
        { type:'books', name: 'My Name is Red', text: '“I don\'t want to be a tree; I want to be its meaning.”', cover: 'my_name_is_redxs.jpg' },   
        { type:'books', name: 'Behave', text: '“You don\'t have to choose between being scientific and being compassionate.”', cover: 'behavexs.jpg' }, 		
        { type:'books', name: 'The Watchmen', text: '“There is no future. There is no past. Do you see? Time is simultaneous, an intricately structured jewel that humans insist on viewing one edge at a time, when the whole design is visible in every facet.”', cover: 'watchmenxs.jpg' },
        { type:'books', name: 'Better Angels of Our Nature', text: '“An ideology can provide a satisfying narrative that explains chaotic events and collective misfortunes in a way that flatters the virtue and competence of believers, while being vague or conspiratorial enough to withstand skeptical scrutiny.”', cover: 'better_angelsxs.jpg' },
        { type:'books', name: 'A Short History of Nearly Everything', text: '“Not one of your pertinent ancestors was squashed, devoured, drowned, starved, stranded, stuck fast, untimely wounded, or otherwise deflected from its life\'s quest of delivering a tiny charge of genetic material to the right partner at the right moment in order to perpetuate the only possible sequence of hereditary combinations that could result -- eventually, astoundingly, and all too briefly -- in you.”', cover: 'short_historyxs.jpg' },
        { type:'books', name: 'The Eye of the World', text: '“The rose petal floats on water. The kingfisher flashes above the pond. Life and beauty swirl in the midst of death.”', cover: 'eye_of_the_worldxs.jpg' },
        { type:'books', name: 'Packing for Mars', text: '“Yes, the money could be better spent on Earth. But would it? Since when has money saved by government redlining been spent on education and cancer research? It is always squandered. Let\'s squander some on Mars. Let\'s go out and play.”', cover: 'packing_for_marsxs.jpg' },
        { type:'books', name: 'The Dark Forest', text: '“It\'s a wonder to be alive. If you don\'t understand that, how can you search for anything deeper?”', cover: 'dark_forestxs.jpg' },
        { type:'books', name: 'The Hobbit', text: '“Do you wish me a good morning, or mean that it is a good morning whether I want it or not; or that you feel good this morning; or that it is a morning to be good on?” ~ Gandalf the Grey', cover: 'the_hobbitxs.jpg' },
    ]
}

export default Favorites;





