import React from 'react';
import PropTypes from 'prop-types';



class Skill extends React.Component {
    render() {
        return(
            <tr>
                <td>{this.props.skill}</td>
                <td className="text-right">				
                    <ul className="list-inline">
                        <li>				
                            {[...Array(this.props.level)].map((e, i) => <span key={i} className="glyphicon glyphicon-star text-danger" aria-hidden="true"></span>)}
                        </li>
                    </ul>
                </td>
            </tr>
        );
    }
}

Skill.propTypes = {
    skill: PropTypes.string,
    level: PropTypes.number
}

export default Skill;

