import React from 'react';
/*
                    <li>Summer Camp Boomerang Kazakhstan
                    https://vimeo.com/groups/129/videos/13994163
                    https://web.archive.org/web/20181125000542/http://www.summercamp.kz/
                    </li>
*/
class Volunteering extends React.Component {
    render() {
        return(
            <div>
                <h1>Volunteering Work</h1>
                <hr/>
                <div className="container text-left">
                    <div className="row mb-3 no-gutter">
                        <div className="col-sm-8 col-xs-12">
                            <h2><a href="http://www.boomerang.kz/" target="_blank" rel="noreferrer">Summer Camp Boomerang</a></h2>
                            <p className="p-1">Teaching english in Kazakhstan over a summer wasn&apos;t always fun but it was a great experience. There were some real challenges - sleeping in an old soviet army tent for a month at a time, meat that wasn&apos;t properly stored and led to a lot of outhouse time, and chilly baths in the creek. It came with real rewards though - making friends with peace corp volunteers, watching the sun rise over the Asian steppe, amazing honey fresh off the comb, and of course teaching a great group of kids excited to learn.</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <img className="w-100" src="/images/volunteering/boomerang.JPG" alt="Summer Camp Boomerang, Taldykorgan Kazakhstan" />
                        </div>
                    </div>
                    <div className="row mb-3 bg-light no-gutter">
                        <div className="col-sm-5 col-xs-12">
                            <img className="w-100 p-1" src="/images/volunteering/summersearch.jpg" alt="Summer Search alumni dashboard" />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <h2><a href="https://summersearch.org/" target="_blank" rel="noreferrer">Summer Search</a></h2>
                            <p className="p-1">Summer Search is a program to provide mentorship to students and experiental learning over summers. I worked for them in a pro-bono capacity, using my technical skills to help them build out a custom dashboarding solution for their alumni data.</p>
                        </div>
                    </div>
                    <div className="row mb-3 no-gutter">
                        <div className="col-sm-8 col-xs-12 ">
                            <h2><a href="https://www.prayinghandsranch.org/" target="_blank" rel="noreferrer">Praying Hands Ranch</a></h2>
                            <p className="p-1">One of the most challenging jobs I&apos;ve ever had and it was unpaid. But it was very rewarding seeing the progress the children made and the bonds they formed with their animals.</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <img className="w-100" src="/images/volunteering/ranch.jpg" alt="Summer Camp Boomerang, Taldykorgan Kazakhstan" />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Volunteering;