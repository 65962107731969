import React from 'react';
import Skills from '../components/Skills';
import Panel from '../components/Panel';
import Timeline from '../components/Timeline';

class Home extends React.Component {
    render() {
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <Panel header="Front-end" icon="glyphicon-hand-up" delay={2} bulletList={["Websites", "Mobile Apps", "Ecommerce"]}/>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <Panel header="Back-end" icon="glyphicon-modal-window" delay={0} bulletList={["REST Services", "SQL & NoSQL", "Integration"]}/>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <Panel header="Infrastructure" icon="glyphicon-cloud" delay={4} bulletList={["Amazon Web Services", "LAMP stack", "Systems Architecture"]}/>
                        </div>
                        <div className="col-md-4 col-sm-6 hidden-md hidden-lg">
                            <Panel header="Frameworks" icon="glyphicon-wrench" delay={4} bulletList={["Spring MVC", "Bootstrap", "React"]}/>	
                        </div>
                    </div>
                    <div className="row text-center">
                        <h1 className="uppercase">Let&apos;s build something amazing together</h1>
                        <p className="lead"><span >&ldquo;</span>I think change needs to be egoless... It&apos;s more important to be part of a process by rolling up your sleeves, being on the ground, initiating projects, starting campaigns - you know, building stuff.<span>&rdquo;</span> ~ Rania Al-Abdullah</p>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-md-4 text-left">
                            <h3 className="text-primary">About me</h3>
                            <p className="text-muted">Careers in marketing and design are often touted as being for ‘creatives’ while software engineering is relegated to mathematical types. I have experience in a number of spaces - teaching, sales, operations, and marketing to name a few - and I’ve never had more need for imagination than in technology. Full-stack development done right should fully engage the creative faculties - from empathizing with the needs of users by building great interfaces to finding innovative solutions to deep technical challenges.</p>
                        </div>
                        <div className="col-sm-4 col-md-4">
                            <img src="../images/me-5.JPG" className="img-circle img-responsive center-block p-2" alt="Photo Ben Maddox" />
                        </div>
                        <div className="col-md-4 text-left">
                            <h3 className="text-primary">Details</h3>
                            <p className="text-muted">Have an interesting project? Know of a great career opportunity? Contact me.</p>
                            <p>	
                                <i className="fa fa-envelope-o" aria-hidden="true"></i> <a href="mailto:webmaster@example.com">Email me</a>
                            </p>
                            <p>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i> <a href="/files/Maddox_Resume_Online.pdf" download>Download my resume</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-light" id="skills">
                    <Skills />
                </div>
                <Timeline />
            </div>
        );
    }
}

export default Home;