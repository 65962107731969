import React from 'react';
import PropTypes from 'prop-types';

class Panel extends React.Component {
    render() {
        return(
                <div className={`panel ${this.props.inverted ? "panel-invert": ""} panel-default panel-highlight animated animated-d-${this.props.delay} fadeInUp`} data-toggle="tooltip" data-placement="top" title="Bootstrap grid responsive design">
                    <div className="panel-body">
                        <span className={`glyphicon ${this.props.icon} glyphicon-large ${this.props.inverted ? "" : "text-secondary"}`}></span>
                        <h3 className="mb-1">
                            {this.props.header}
                        </h3>
                        <ul className={`list-lined ${this.props.inverted ? "list-lined-invert": ""}`}>
                            {this.props.bulletList.map((bullet, index) => (
                                <li key={index}>{bullet}</li>
                            ))}
                        </ul>
                    </div>
                </div>
        );
    }
}

Panel.propTypes = {
    header: PropTypes.string,
    bulletList: PropTypes.array,
    icon: PropTypes.string,
    delay: PropTypes.number,
    inverted: PropTypes.bool
}

Panel.defaultProps = {
    header: "Front-end",
    bulletList: ["Websites", "Mobile Apps", "Ecommerce"],
    icon: "glyphicon-modal-window",
    delay: 0,
    inverted: false,
}

export default Panel;