import React from 'react';
import Panel from '../components/Panel';
//style="font-size:1.5em; color:white; padding-bottom:.1em"

class Skyrim extends React.Component {
    render() {
        return(
            <div className="bg-secondary shadow-inverse">
                <div className="container">
                    <div className="text-right">
                        <h1 className="text-uppercase underline">Skyrim Mod</h1>
                    </div>
                    <div className="row mt-3">
                    </div>
                    <div className="container mt-3">
                        <div className="row text-dark">
                            <div className="col-md-4 col-sm-6">
                                <Panel header="Scripted Events" icon="fa fa-terminal fa-large" delay={2} bulletList={["Ship lost at sea", "Gamble on crab fights", "Buy pet companions"]} inverted={false}/>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <Panel header="New Art Packs" icon="fa fa-paint-brush fa-large" delay={0} bulletList={["Tropical assets from Soolie", "volvaga0's face masks assets", "New textures"]} inverted={false}/>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <Panel header="Lore Friendly" icon="fa fa-ravelry fa-large" delay={4} bulletList={["Explore Senchal", "Set after White Gold Concordat", "All new questline"]} inverted={false}/>
                            </div>
                        </div>
                    </div>
                    <div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="4000">
                        <ol className="carousel-indicators">
                            <li  data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            {[...Array(13)].map((e, i) => 
                                <li key={i} className="carousel-indicator-large" data-target="#myCarousel" data-slide-to={i + 1}></li>
                            )}
                        </ol>
                        <div className="carousel-inner">
                            <div className="item active ">
                            <img src="../images/mod/ScreenShot1.jpg" alt="Senchal Mod" />
                            <div className="carousel-caption">
                                <h3>Senchal</h3>
                                <p>A Skyrim Mod</p>
                            </div>
                            </div>
                            
                            {[...Array(13)].map((e, i) =>
                            <div  key={i} className="item">
                                 <img src={`../images/mod/ScreenShot${i+2}.jpg`} alt="Senchal Mod" />
                                 </div> 
                            )}
                            
                        </div>
                        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>


                <div className="row mt-3">
                </div>		
            </div>
        );
    }
}

export default Skyrim;