import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { GeoJSON } from 'react-leaflet/GeoJSON';
import Country from '../components/Country';
import countries from '../data/countries/visited.geo.json';


class Travel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            country: null,
            currentLayer: null,
            photos: {}
        };
        this.baseStyle = {
            stroke: true,
            color: '#F1670B',
            opacity: 0.3,
            fill: true,
            fillColor: '#F1670B',
            fillOpacity: 0.3
        }
        this.selectedStyle = {
            stroke: true,
            color: '#ff0000',
            opacity: 1,
            fill: true,
            fillColor: '#ff000',
            fillOpacity: 0.5   
        }
    }

    interaction = (feature, layer) => {
        layer.on({
            click: this.countryClicked
        })
    }

    countryClicked = (e) => {
        if (e && e.sourceTarget){
            let countryData =  e.sourceTarget.feature.properties;

            if(this.state.currentLayer){
                this.state.currentLayer.setStyle(this.baseStyle)
            }
            const layer = e.target;
            layer.setStyle(this.selectedStyle);

            this.setState(({
                country: countryData,
                currentLayer: layer
            }));
        }
    }

    // eslint-disable-next-line no-unused-vars
    setStyle = (feature) => {
        return this.baseStyle;
    }

    render() {
        //const position = [51.505, -0.09]
        return(
            <div className="bg-secondary shadow-inverse">
                <div className="container">
                    <div className="row">
                        <div className="text-right">
                            <h1 className="text-uppercase underline">Countries Visited</h1>
                        </div>
                        <MapContainer className="map" center={[22, 0]} zoom={2} scrollWheelZoom={false}>
                            <TileLayer
                                maxZoom={20}
                                attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributor'
                                url="https://tiles.stadiamaps.com/tiles/stamen_toner_background/{z}/{x}/{y}{r}.{ext}"
                                ext={'png'}
                            />
                            <TileLayer
                                maxZoom={20}
                                attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributor'
                                url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.{ext}"
                                ext={'png'}
                                opacity={.5}
                            />
                            <GeoJSON
                                data={countries}
                                style={this.setStyle}
                                onEachFeature={this.interaction}
                            />
                        </MapContainer>
                    </div>
                    <Country countryData={this.state.country} />
                    <div className="row mt-3">
                </div>
                </div>
            </div>
        );
    }
}


export default Travel;


/*
                    <Marker position={position}>
                        <Popup>
                            <div>
                                <h1>Hi</h1>
                                A pretty CSS3 popup. <br /> Easily customizable.

                            </div>
                        </Popup>
                    </Marker>
*/

/* I dont like French Guiana being on the map so you can delete it from the geojson file by finding and deleting:
[[[-52.55642473001839,2.504705308437053],[-52.93965715189498,2.124857692875622],[-53.418465135295264,2.053389187016037],[-53.554839240113495,2.334896551925965],[-53.77852067728889,2.376702785650053],[-54.08806250671728,2.105556545414629],[-54.52475419779975,2.311848863123785],[-54.27122962097578,2.738747870286943],[-54.18428402364474,3.194172268075235],[-54.01150387227682,3.622569891774858],[-54.399542202356514,4.212611395683481],[-54.47863298197922,4.896755682795643],[-53.95804460307093,5.756548163267809],[-53.618452928264844,5.646529038918402],[-52.88214128275408,5.409850979021599],[-51.82334286152593,4.565768133966145],[-51.65779741067888,4.156232408053029],[-52.24933753112398,3.241094468596287],[-52.55642473001839,2.504705308437053]]]
*/

//find more! https://leaflet-extras.github.io/leaflet-providers/preview/

//dark
//url "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
//'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'

//darker
//https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png
//&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>

//original
//'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//url https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png

//water color
//
//ext={'jpg'}


