import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Favorites from './pages/Favorites';
import Home from './pages/Home';
import EscapeRoom from './pages/EscapeRoom';
import Skyrim from './pages/Skyrim';
import AlexaEye from './pages/AlexaEye';
import Hue from './pages/Hue';
import CloudCity from './pages/CloudCity';
import Travel from './pages/Travel';
import Volunteering from './pages/Volunteering';



function App() {
  return (
    <div className="App">
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="volunteer" element={<Volunteering />} />
          <Route path="travel" element={<Travel />} />
          <Route path="projects/escaperoom" element={<EscapeRoom />} />
          <Route path="projects/skyrim" element={<Skyrim />} />
          <Route path="projects/alexaeye" element={<AlexaEye />} />
          <Route path="projects/hue" element={<Hue />} />
          <Route path="projects/cloudcity" element={<CloudCity />} />
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
