import React from 'react';
import PropTypes from 'prop-types';
//style="cursor: pointer"

class TimelineMoment extends React.Component {

    render() {
        let moment = this.props.moment;

        return(
            <li className={moment.invert ? "timeline-inverted" : "" }>
                <div className={`small timeline-badge ${moment.badge}`}>&apos;{moment.dateBadge}</div>
                <div className="timeline-panel clickable" data-toggle="modal" data-target={`#${moment.id}`} >
                    <a href={moment.company.link} target="_blank" className="no-modal pull-right"  rel="noreferrer">
                        <img className="media-object media-object-small" src={`../images/logos/${moment.company.logo}`} alt={moment.company.name} />
                    </a>
                    <div className="timeline-heading">
                        <h4 className="timeline-title">{moment.name}</h4>
                        <p><small className="text-muted"><i className="glyphicon glyphicon-time"></i> {moment.company.dates}</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>{moment.company.blurb}</p>
                    </div>
                </div>
                <div className={`hidden-xs ${moment.invert ? "quote-inverted" : "quote"}`} data-aos={moment.invert ? "fade-right" : "fade-left"}>
                    <p><em>&quot;{moment.quote.text}&quot;</em></p>
                    <p className="text-right"><strong>{moment.quote.name}</strong>, {moment.quote.title}</p>
                </div>
                <div className="modal fade" id={moment.id} role="dialog">
                    <div className="modal-dialog">					
                        <div className="modal-content">
                            <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <div>&nbsp;</div>
                            <p><em>&quot;{moment.quote.text}&quot;</em></p>
                            <p className="text-right"><strong>{moment.quote.name}</strong>, {moment.quote.title}</p>
                            </div>
                        </div>					  
                    </div>
                </div>
            </li>
        );
    }
}

TimelineMoment.propTypes = {
    moment: PropTypes.object
}

export default TimelineMoment;

