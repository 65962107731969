import React from 'react';
import PropTypes from 'prop-types';

//image style style="width:100%;"
//paragraph style="font-weight: 300;"

class Card extends React.Component {

    render() {
        return(
            <div className="flip-container-card">
                <div className="flipper" tabIndex="0" role="button" onClick={flipCover} onKeyDown={handleBtnKeyPress}>
                    <div className="front">
                        <img alt={this.props.name} src={`../images/${this.props.type}/${this.props.image}`}  />
                    </div>
                    <div className="back inner-glow">
                        <p className="p-2" >{this.props.text}</p>
                    </div>
                </div>
            </div>
        );
    }
}

function flipCover(event) {
    event.preventDefault();
    var element = event.currentTarget;
    if (element.classList.contains('flip')){
        element.classList.remove('flip')
    } else {
        element.classList.add('flip');
    } 
}

function handleBtnKeyPress (event) {
    if (event.keyCode === 32 || event.keyCode === 13) {
        event.preventDefault();
        flipCover(event);	  
    }
}

Card.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string
}

export default Card;