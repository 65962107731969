import React from 'react';
import PropTypes from 'prop-types';

import Skill from './Skill';

class Skills extends React.Component {
    render() {
        return(
            <div className="container ">
                <div className="row mt-3 mb-3">
                    <div className="col-md-2 text-right">
                        <h3>Languages</h3>
                    </div>
                    <div className="col-md-4">
                        <table className="table skill-table">
                            <tbody className="hover-expand">
                                {this.props.skills.map((skill, index) => (
                                    <Skill skill={skill.skill} level={skill.level} key={index}/>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-4">
                        <table className="table skill-table">
                            <tbody className="hover-expand">
                                {this.props.tools.map((tools, index) => (
                                    <Skill skill={tools.skill} level={tools.level} key={index}/>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-2 text-left">
                        <h3>Tools &amp; Frameworks</h3>
                    </div>
                </div>
            </div>
        );
    }
}

Skills.propTypes = {
    skills: PropTypes.array,
    tools: PropTypes.array,

}

Skills.defaultProps = {
    skills: [
        {skill:"Java", level:4},
        {skill:"Javascript", level:4},
        {skill:"C#", level:3},
        {skill:"Python", level:3},
        {skill:"C++", level:2},
        {skill:"Swift", level:2}
    ],
    tools: [
        {skill:"Spring MVC", level:4},
        {skill:"Play!", level:4},
        {skill:"React", level:3},
        {skill:"Mulesoft", level:3},
        {skill:"VS Code", level:3},
        {skill:"AngularJS", level:2}
    ]
}

export default Skills;

