import React from 'react';

import HTMLFlipBook from 'react-pageflip'





class EscapeRoom extends React.Component {

    render() {
        return(
            <div className="bg-secondary shadow-inverse">
                <div className="container">
                    <div className="text-right">
                        <h1 className="text-uppercase underline">Escape Room</h1>
                    </div>
                    <div className="bg-black">
                        <div className="p-md-5">
                            <HTMLFlipBook width={324} height={512} showCover={true} >
                                {[...Array(10)].map((e, i) => 
                                    <div key={i} className="book-page">
                                        <img src={`../images/pages/${i + 1}.jpg`}/>
                                    </div>
                                )}
                            </HTMLFlipBook>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <p className="lead mb-3">
                        Virtual Escape Room
                    </p>
                </div>
            </div>
        );
    }
}

export default EscapeRoom;