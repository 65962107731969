import React from 'react';
import {
    Link  
} from "react-router-dom";

class Nav extends React.Component {
    render() {
        return(
            <nav className="navbar nav-primary">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>                        
                        </button>
                        <a className="navbar-brand" href="/"><img className="" src="../images/logos/logo32.png"/></a>
                    </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="nav navbar-nav navbar-right navbar-primary" >
                            <li className="active" data-toggle="collapse" data-target=".navbar-collapse.in"><Link to="/">Home</Link></li>
                            <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">About<span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li data-toggle="collapse" data-target=".navbar-collapse.in"><Link to="/favorites">Favorite Media</Link></li>
                                    <li data-toggle="collapse" data-target=".navbar-collapse.in"><Link to="/travel">Travel</Link></li>
                                    <li data-toggle="collapse" data-target=".navbar-collapse.in"><Link to="/volunteer">Volunteering</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">Projects<span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li data-toggle="collapse" data-target=".navbar-collapse.in"><Link to="/projects/skyrim">Skyrim Mod</Link></li>
                                    <li><Link to="/projects/escaperoom">EscapeRoom</Link></li>
                                    <li><Link to="/projects/alexaeye">Alexa Eye</Link></li>
                                    <li><Link to="/projects/hue">Ambient Hue</Link></li>
                                    <li><Link to="/projects/cloudcity">Cloud City</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">Colleagues<span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                <li><a href="http://nickbjones.net/" target="_blank" rel="noreferrer">Nick B Jones</a></li>
                                <li><a href="http://www.westrevino.com/" target="_blank" rel="noreferrer">Wes Trevino</a></li>
                                <li><a href="http://jaypl.com/" target="_blank" rel="noreferrer">Jay Langhurst</a></li>
                                </ul>
                            </li>
                            <li><a href="/">Code</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Nav;
