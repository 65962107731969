import React from 'react';

class Footer extends React.Component {
    render() {
        return(
            <div>
                <footer className="dark bg-secondary shadow-inverse">
                    <div className="container text-center">
                        <a href="#"><img src="../images/logos/logo64.png" className="icon-social"/></a>
                        <h3 className="uppercase">Ben Maddox</h3>
                        <div>
                            <span><a href="https://www.linkedin.com/in/btmaddox" target="_blank" rel="noreferrer"><img src="../images/logos/linkedin.png" className="icon-social"/></a></span>
                            <span><a href="https://twitter.com/benjammaddox" target="_blank" rel="noreferrer"><img src="../images/logos/twitter.png" className="icon-social"/></a></span>
                            <span><a href="https://steamcommunity.com/id/benmaddox" target="_blank" rel="noreferrer"><img src="../images/logos/steam.png"className="icon-social"/></a></span>
                            <span><a href="https://www.facebook.com/maddox.benjamin" target="_blank" rel="noreferrer"><img src="../images/logos/facebook.png" className="icon-social"/></a></span>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
