
import React from 'react';
import Nav from './Nav';

class Header extends React.Component {
    render() {
        return(
            <div className="jumbotron shadow-inverse">
                <Nav></Nav>
                <div className="container vertical-center">
                <div>
                    <h1>Ben Maddox</h1>
                    <p>Full-Stack Developer</p>  
                </div>
                </div>
            </div>
        );
    }
}

export default Header;





